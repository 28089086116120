// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Followers from "../../blocks/followers/src/Followers";
import Sfdcintegration from "../../blocks/sfdcintegration/src/Sfdcintegration";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import FacialTracking from "../../blocks/facialtracking/src/FacialTracking";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import Customiseinterface2 from "../../blocks/customiseinterface2/src/Customiseinterface2";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Maps from "../../blocks/maps/src/Maps";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EventRegistration from "../../blocks/eventregistration/src/EventRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Trending from "../../blocks/trending/src/Trending";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Googleassistantintegration2 from "../../blocks/googleassistantintegration2/src/Googleassistantintegration2";
import Asknatasha from "../../blocks/asknatasha/src/Asknatasha";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Facetagging2 from "../../blocks/facetagging2/src/Facetagging2";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import CommunityForum from "../../blocks/communityforum/src/CommunityForum";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Polling from "../../blocks/polling/src/Polling";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import Search from "../../blocks/search/src/Search";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import AllEvents from "../../blocks/events/src/AllEvents";
import CreateEvent from "../../blocks/events/src/CreateEvent";
import EventDetail from "../../blocks/events/src/EventDetail";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Videos from "../../blocks/videos/src/Videos";


const routeMap = {
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Followers:{
 component:Followers,
path:"/Followers"},
Sfdcintegration:{
 component:Sfdcintegration,
path:"/Sfdcintegration"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
FacialTracking:{
 component:FacialTracking,
path:"/FacialTracking"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
QrCodes:{
 component:QrCodes,
path:"/QrCodes"},
Customiseinterface2:{
 component:Customiseinterface2,
path:"/Customiseinterface2"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Maps:{
 component:Maps,
path:"/Maps"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EventRegistration:{
 component:EventRegistration,
path:"/EventRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Trending:{
 component:Trending,
path:"/Trending"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Googleassistantintegration2:{
 component:Googleassistantintegration2,
path:"/Googleassistantintegration2"},
Asknatasha:{
 component:Asknatasha,
path:"/Asknatasha"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Facetagging2:{
 component:Facetagging2,
path:"/Facetagging2"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Download:{
 component:Download,
path:"/Download"},
DownloadUpload:{
 component:DownloadUpload,
path:"/DownloadUpload"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Polling:{
 component:Polling,
path:"/Polling"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
Search:{
 component:Search,
path:"/Search"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
AllEvents:{
 component:AllEvents,
path:"/AllEvents"},
CreateEvent:{
 component:CreateEvent,
path:"/CreateEvent"},
EventDetail:{
 component:EventDetail,
path:"/EventDetail"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Videos:{
 component:Videos,
path:"/Videos"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;
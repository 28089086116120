import React from "react";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Library needs replacing - 4 years old
// import * as faceApi from "face-api.js";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
// type DetectionType = faceApi.WithAge<
//   faceApi.WithGender<
//     faceApi.WithFaceExpressions<
//       faceApi.WithFaceLandmarks<
//         {
//           detection: faceApi.FaceDetection;
//         },
//         faceApi.FaceLandmarks68
//       >
//     >
//   >
// >;
// Customizable Area End

interface S {
  // Customizable Area Start
  faceDetected: boolean;
  canDetect?: boolean;
  expressionInfo?: { expression: string; probability: unknown };
  expressionMap: Record<string, string>;
  age?: number;
  gender?: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface Expression {
  expression: string;
  probability: unknown;
}
// Customizable Area End

export default class FacialTrackingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  videoRef = React.createRef<HTMLVideoElement>();
  overlayRef = React.createRef<HTMLCanvasElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      faceDetected: false,
      expressionMap: {
        neutral: "😶",
        happy: "😄",
        sad: "😞",
        angry: "🤬",
        fearful: "😖",
        disgusted: "🤢",
        surprised: "😲",
      },
      // Customizable Area End
    };

    // Customizable Area Start
    this.onPlay = this.onPlay.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    this.videoRef.current ? (this.videoRef.current.muted = true) : "";
    return this.runModels();
  }

  async componentWillUnmount(): Promise<void> {
    this.turnOffCamera();
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>
  ): Promise<void> {
    const currentVideo = this.videoRef.current;

    if (
      this.state.canDetect &&
      !prevState.canDetect &&
      navigator.mediaDevices
    ) {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
      });

      currentVideo && (currentVideo.srcObject = mediaStream);
    } else if (prevState.canDetect && !this.state.canDetect) {
      this.turnOffCamera();

      currentVideo && (currentVideo.srcObject = null);

      this.setState({
        expressionInfo: undefined,
        age: undefined,
        gender: undefined,
      });
    }
  }

  async runModels() {
    // try {
    //   await faceApi.nets.tinyFaceDetector.loadFromUri("/models");
    //   await faceApi.nets.faceLandmark68Net.loadFromUri("/models");
    //   await faceApi.nets.ageGenderNet.loadFromUri("/models");
    //   await faceApi.nets.faceExpressionNet.loadFromUri("/models");
    // } catch (error: unknown) {
    //   this.showAlert(configJSON.alertTitle, configJSON.alertMessage);
    // }
  }

  async detectFace(videoEl: HTMLVideoElement) {
    // return faceApi
    //   .detectSingleFace(videoEl, new faceApi.TinyFaceDetectorOptions())
    //   .withFaceLandmarks()
    //   .withFaceExpressions()
    //   .withAgeAndGender();
  }

  drawDetections(
    overlayEl: HTMLCanvasElement,
    videoEl: HTMLVideoElement,
    detection: any
  ) {
    // const dims = faceApi.matchDimensions(overlayEl, videoEl, true);
    // faceApi.draw.drawDetections(
    //   overlayEl,
    //   faceApi.resizeResults(detection, dims)
    // );
  }

  onPlay() {
    setInterval(async () => {
      try {
        if (!this.overlayRef?.current || !this.videoRef?.current) return;

        const detection: any | undefined = await this.detectFace(
          this.videoRef.current
        );

        if (this.videoRef.current?.ended || !detection) {
          return;
        }

        this.drawDetections(
          this.overlayRef.current,
          this.videoRef.current,
          detection
        );

        const mostProbableExpression = [
          ...Object.entries(detection?.expressions),
        ]
          .map((element) => {
            return { expression: element[0], probability: element[1] };
          })
          .reduce(
            (
              prev: { expression: string; probability: unknown },
              currentItem: { expression: string; probability: unknown }
            ) => {
              return (currentItem.probability as number) >
                (prev.probability as number)
                ? currentItem
                : prev;
            },
            { expression: "", probability: 0 }
          );

        this.setState({
          expressionInfo: mostProbableExpression as Expression,
          age: detection.age,
          gender: detection.gender,
        });
      } catch (error) {
        this.showAlert(configJSON.alertTitle, configJSON.alertMessage);
      }
    }, 100);
  }

  toggleCanDetect = () => {
    this.setState((prevState: Readonly<S>) => ({
      canDetect: !prevState["canDetect"],
    }));
  };

  turnOffCamera = () => {
    const stream: MediaStream | null | undefined = this.videoRef.current
      ?.srcObject as MediaStream;

    const tracks = stream?.getTracks();

    tracks?.forEach(function (track) {
      // stopping every track
      track.stop();
    });
  };
  // Customizable Area End
}
